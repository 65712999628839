<template>
    <div>
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
      <b-row class="match-height">
        <b-col
          lg="12"
          md="12"
        >
       
        <b-card
          title="Edit Supplier">
          <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-1"
          >
            <div class="alert-body">
              {{error_message}}
            </div>
          </b-alert>
          
          <b-form @submit="formSubmit">
            
            <b-row>
              
              <b-col md="12">
    
                <b-row>
                  <b-col md="6">
                        <b-form-group
                        label="Company Name"
                        class="required legend_top_space_mobile"
                        >
                        <b-form-input
                            placeholder="2 to 50 characters"
                            v-model="form.company_name"
                            autocomplete="off"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label="Supplier Code"
                        class="required legend_top_space_mobile"
                        >
                        <b-form-input
                            placeholder="Supplier Code"
                            v-model="form.supplier_code"
                            autocomplete="off"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label="Supplier Name"
                        class="required legend_top_space_mobile"
                        >
                        <b-form-input
                            placeholder="2 to 50 characters"
                            v-model="form.name"
                            autocomplete="off"
                        />
                        </b-form-group>
                    </b-col>

                    <!-- <b-col md="6">
                        <b-form-group
                        label="Address"
                        class=""
                        >

                        <vue-google-autocomplete
                            id="map"
                            classname="form-control"
                            placeholder=""
                            v-on:placechanged="getAddressData"
                            :country="['sg','tha']"
                            v-model="form.address"
                        >
                        </vue-google-autocomplete>
                        </b-form-group>
                        
                    </b-col> -->
                    <b-col md="6">
                        <b-form-group
                        label="Address"
                        class=""
                        >
                        <b-form-input placeholder="Address" v-model="form.address" autocomplete="off" />
                        </b-form-group>
                        
                    </b-col>

                    <b-col md="6">
                        <b-form-group
                          label="Phone Number"
                        
                        >
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="SmartphoneIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                            
                              type="text"
                              placeholder="Mobile"
                              v-model="form.phone_number"
                              autocomplete="off"
                            
                            />
                          </b-input-group>
                      
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Fax" >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="TelephoneIcon" />
                          </b-input-group-prepend>
                          <b-form-input type="text" placeholder="Mobile" v-model="form.fax" autocomplete="off" />
                        </b-input-group>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Attn." >
                        <b-form-input placeholder="Attn." v-model="form.attn" autocomplete="off" />
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Minimum Order Amount"
                        class="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="DollarSignIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                
                            type="text"
                            placeholder=""
                            v-model="form.moq"
                            autocomplete="off"
                
                          />
                        </b-input-group>
          
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group
                        label="Delivery Fee"
                        class="required"
            
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="DollarSignIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                
                            type="text"
                            placeholder=""
                            v-model="form.delivery_fee"
                            autocomplete="off"
                
                          />
                        </b-input-group>
          
                      </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mb-1">
                    
                  <b-col md="6">
                    <b-form-checkbox
                      v-model="form.fee_weiver"
                      value="yes"
                      unchecked-value="no"
                      class="mb-1 mt-1"
                    >
                      Delivery Fee Waiver
                    </b-form-checkbox>

                  </b-col>

                  <!-- <b-col md="6">
                    <b-form-checkbox
                      v-model="form.multiple_delivery"
                      value="yes"
                      unchecked-value="no"
                      class="mb-1 mt-1"
                    >
                      Multiple Delivery
                    </b-form-checkbox>

                  </b-col> -->
              
                </b-row>

                <b-row>
                      <b-col md="12">
                        <div class="fsmTable table-responsive" style="margin-bottom: 0px !important;">
                            <table class="table">
                                <thead>
                                  <tr>
                                        <th scope="col" style="width: 50%">Name <span class="text-danger">*</span></th>
                                        <th scope="col" style="width: 50%">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in form.emails" :key="index">
                                        <td>
                                            <b-form-input autocomplete="off" placeholder="2 to 50 characters" v-model="item.name"/>
                                        </td>
                                        <td>
                                            <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="item.email"/>
                                        </td>
                                        <td class="text-center">
                                            <b-button class="float-right" variant="danger" @click="removeRow(index);">-</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      </b-col>
                  </b-row>
                  <b-row class="mb-1">
                      <b-col md="12" class="text-center">
                        <hr>
                          <b-button class="mt-1 under_construct_mobile_add_more_btn" variant="primary" @click="addRow">Add More</b-button>
                      </b-col>
                  </b-row>
                
               
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="warning"
                      class="mr-1"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      @click="goBack()"
                    >
                      Cancel
                    </b-button>
                  </b-col>
              </b-row>
    
    
              </b-col>
            </b-row>
    
          </b-form>
        </b-card>
        </b-col>
      </b-row>
    
      
    </div>
    </template>
    
    <script>
    
    import {
      BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    
    export default {
      components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormSelect,
        BFormSelectOption,
        BFormText,
        BInputGroupPrepend,
        BInputGroup,
        BMedia,
        BAvatar,
        BTable,
        BModal,
        VueCropper,
        BFormRadio,
        BFormTimepicker,
        VueTimepicker,
        BBreadcrumb,
        VueGoogleAutocomplete
      },
      directives: {
        Ripple,
      },
      data() {
        return {
    
          
          error_message:null,
          showDismissibleAlert:false,
    
          form :{
            company_name: '',
            supplier_code:'',
            name: '',
            address: '',
            delivery_fee: '',
            moq: '',
            fee_weiver: 'no',
            //multiple_delivery: 'no',
            emails: [{ name:'',email: ''}],
            phone_number: '',
            lat: null,
            long: null,
            id:'',

            fax: '',
            attn: ''            
          },
      
          
        }
      },
      methods : {
    
        formSubmit(e){
          e.preventDefault();
    
          return this.$store.dispatch(POST_API, {
               data:{
                 items:this.form
               },
               api: '/api/update-supplier'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Record Updated Successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    }).then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name:'suppliers'})
                    });
                    
                }
            });
          
        },
    
        
        getDetail(){
          return this.$store.dispatch(POST_API, {
               data:{
                  id:this.$route.params.id,
               },
               api: '/api/supplier-detail'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.form.id = data._id;
                    
                    this.form.address = data.address;
                    this.form.company_name = data.company_name;
                    this.form.supplier_code = data.supplier_code;
                    this.form.name = data.name;
                    this.form.emails = data.emails;
                    this.form.phone_number = data.phone_number;
                    this.form.lat = data.lat;
                    this.form.long = data.long;

                    this.form.moq = data.moq;
                    this.form.delivery_fee = data.delivery_fee;
                    this.form.fee_weiver = data.fee_weiver;
                    this.form.multiple_delivery = data.multiple_delivery;

                    this.form.fax = data.fax;
                    this.form.attn = data.attn;
                    return this.form;
                }
            });
        },
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Inventory'
          },{
            to:null,
            text: 'Settings'
          },{
            to:{name:'suppliers'},
            text: 'Suppliers Info',
          },{
            to:null,
            text: 'Edit Supplier',
          }];
          return item;
        },
        goBack(){
          this.$router.push({ name:'suppliers'})
        },
        getAddressData(place, placeResultData, id){

            this.form.address = placeResultData.formatted_address;
            this.form.lat = place.latitude;
            this.form.long = place.longitude;
        },
        addRow() {
          this.form.emails.push({
            name:'',
            email: ''
          })
        },

        removeRow(index) {

          this.form.emails.splice(index, 1);

          if (this.form.emails.length == 0) {
            this.addRow();
          }
        },
        
    
      },
      mounted(){
       
        this.getDetail();
      }
    }
    </script>
    